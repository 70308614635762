<template>
  <div id="HomePage">
    <div class="header-" id="headers"  v-if="!$route.meta.hideHeader">
      <Header></Header>
    </div>
    <!-- 轮播图 -->
    <div id="swiper" class="container-fuild">
      <div class="swiper-container banner-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide"><!--
            <img class="swiper-lazy" :data-src="item.img" alt="轮播图">-->
            <div class="swiper-lazy"></div>
            <div class="swiper-lazy-preloader"></div>
            <div class="swiper-slide-title">
              <h1 class="gradient-text">联系我们</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 大数据管理系统 -->
    <div id="bigData" class="container-fuild">
      <div class="row g-0 justify-content-center">
        <div class="col-lg-8 wow fadeInUp" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">
          <p class="text-center mb-4" style="font-size: 30px">提交反馈</p>
          <form>
            <div class="row g-3">
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="name" placeholder="Your Name"  style="font-size:20px;">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="email" class="form-control" id="email" placeholder="Your Email"  style="font-size:20px;">

                </div>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <input type="text" class="form-control" id="subject" placeholder="Subject"  style="font-size:20px">

                </div>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <textarea class="form-control" placeholder="Leave a message here" id="message" style="font-size:25px;height: 200px"></textarea>

                </div>
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-primary rounded-pill py-3 px-5" type="submit" @click="message">提交</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div id="bigData" class="container-fuild">
      <div class="row bigData-container">
        <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn">
          <div id="map"></div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6">
          <div class="bigData-title">
          </div>
          <h4 class="bigData-device">大谷物联科技有限公司</h4>
          <p class="contact">
            地址：深圳市龙华区壹成环智中心-C栋2501
          </p>
          <p class="contact">
            邮箱：tangchenxi@daguiot.com
          </p>
          <p class="contact">
            微信号: szdaguiot
          </p>
          <p class="contact">
            Tel：184-7617-9523
          </p>
        </div>
      </div>
    </div>
    <Footer ></Footer>

  </div>
</template>
<script>
import Swiper from "swiper";
import {WOW} from 'wowjs';
import router from "../router";

export default {
  name: "HomePage",
  data() {
    return {
      map: null
    }
  }, methods: {
    toContactMe(){
      router.push("contact")
    },
    message(){
      this.$message({
        message: "提交反馈成功",
        type: 'success'
      });
    },
    initMap() {
      var map = new L.map('map');
      var osmUrl = "http://rt0.map.gtimg.com/realtimerender?z={z}&x={x}&y={-y}&type=vector&style=0";
      var osm = new L.tileLayer(osmUrl, {minZoom: 17, maxZoom: 18});
      map.addLayer(osm);
      map.setView(new L.LatLng(22.643640, 114.030347), 17)
      var myIcon = L.divIcon({
        html: '<svg t="1701074075183" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4095" width="48" height="48"><path d="M895.616384 347.812188q0 10.22977-0.511489 19.436563t-1.534466 19.436563q-9.206793 84.907093-37.338661 163.164835t-71.096903 150.377622-99.228771 138.613387-121.734266 127.872128q-9.206793 11.252747-23.528472 11.252747-15.344655 0-24.551449-11.252747-65.470529-61.378621-122.245754-128.895105t-100.251748-141.170829-71.608392-152.935065-36.315684-165.210789q0-8.183816-0.511489-15.344655t-0.511489-15.344655q0-71.608392 28.131868-135.032967t76.211788-110.481518 113.038961-74.677323 138.613387-27.62038 138.101898 27.62038 112.527473 74.677323 76.211788 110.481518 28.131868 135.032967zM540.643357 507.396603q33.758242 0 63.424575-12.787213t51.66034-34.26973 34.781219-50.637363 12.787213-61.89011-12.787213-61.89011-34.781219-50.637363-51.66034-34.26973-63.424575-12.787213-63.424575 12.787213-52.171828 34.26973-35.292707 50.637363-12.787213 61.89011 12.787213 61.89011 35.292707 50.637363 52.171828 34.26973 63.424575 12.787213z" p-id="4096" fill="#d81e06"></path></svg>',
        className: 'my-div-icon',
        iconSize: 60
      });
      this.map = map; // data上需要挂载// 加载底图
      L.marker([22.643750, 114.030547], {
        icon: myIcon
      }).addTo(map)
    }
  }
  ,
  mounted() {
    this.initMap();
    /* banner-swiper */
    new Swiper(".banner-swiper", {
      loop: true, // 循环模式选项
      effect: 'fade',
      //自动播放
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      // 延迟加载
      lazy: {
        loadPrevNext: true
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true //修改swiper的父元素时，自动初始化swiper
    });
    /* customer-swiper */
    /* wowjs动画 */
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    })
    wow.init();
  }
};
</script>
<style scoped>
#bigData .contact{
  color: #8c8c8c;
  font-size: 20px;
  line-height: 5rem;
}
.justify-content-center {
  justify-content: center !important;
}
.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y)* -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
@media (min-width: 992px) {
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
}
.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.fadeInUp {
  animation-name: fadeInUp;
}
.text-center {
  text-align: center !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}
.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y)* -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.form-floating {
  position: relative;
}
.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}
.form-floating>.form-control, .form-floating>.form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem .75rem;
}
.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #777;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem .75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
label {
  display: inline-block;
}
#map {
  width: 800px;
  height: 500px;
  margin: 50px auto;
}

/* 整体盒子 */
#HomePage {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
}

.zoomIn {
  display: flex;
  justify-content: center;
}

/* 轮播图 */
#swiper {
  height: 310px;
}

#swiper .banner-swiper {
  width: 100%;
  height: 100%;
}

#swiper .banner-swiper .swiper-slide img {
  width: 100%;
  height: 100%;
}

#swiper .banner-swiper .swiper-slide {
  position: relative;
}

#swiper .banner-swiper .swiper-slide-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 3% 0% 0% 20%;
  background-image: linear-gradient(115deg, #66afe9 0%, #ffffff 30%, #f2dede 100%, #31B0D5 50%);

  line-height: 37px;
}

#swiper .banner-swiper .swiper-slide-title > h1 {
  font-size: 50px;
  margin-top: 5%;
}

#swiper .banner-swiper .swiper-slide-title > p {
  font-size: 14px;
  color: black;
  height: 20px;
  margin: auto;

}

/* App产品介绍 */
#bigData {
  padding: 100px 220px;
  transition: all ease 0.6s;
  box-sizing: border-box;
  animation: fadeIn 2s ease-in-out forwards;
}

.row {
  padding: 5% 12%;
  background-image: linear-gradient(115deg, #83C0EF 0%, #f5f1e8 30%);
  border-radius: 40px;
}

#bigData .bigData-title {
  padding-top: 70px;
  padding-bottom: 10px;
}

.gradient-text {
  font-size: 42px;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: black;
  font-weight: bolder;
}

#bigData p {
  font-size: 15px;
  color: #333;
  line-height: 2rem;
}

#bigData .bigData-device {
  font-weight: 800;
  font-size: 40px;
  margin: 50px 0 80px;
  background: linear-gradient(to right, #ff6699, #66ccff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
//color: linear-gradient(115deg,#83C0EF 0%,#f5f1e8 30%);
}


/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  #swiper {
    height: 200px;
  }

  #bigData {
    padding: 30px;
  }

  #bigData .bigData-device {
    font-size: 20px;
    margin: 10px 0 10px;
  }


}

/* 媒体查询（平板） */
@media screen and (min-width: 768px) and (max-width: 996px) {
  #swiper {
    height: 400px;
  }

  #bigData {
    padding: 60px;
  }

  #bigData .bigData-device {
    font-size: 30px;
    margin: 30px 0 15px;
  }

  #contactUs .contactUs-container h1 {
    font-size: 30px;
  }

  #contactUs .contactUs-container h3 {
    font-size: 20px;
  }

  #contactUs .contactUs-container button {
    width: 300px;
    height: 50px;
    margin-top: 30px;
  }

  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 15px;
  }

}
.px-5{
  width: 100px;
  border-radius: 25px;
}
</style>

