<template>
  <div id="HomePage">
    <div class="header-" id="headers">
      <Header></Header>
    </div>
    <!-- 轮播图 -->
    <div id="swiper" class="container-fuild">
      <div class="swiper-container banner-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide"><!--
            <img class="swiper-lazy" :data-src="item.img" alt="轮播图">-->
            <div class="swiper-lazy"></div>
            <div class="swiper-lazy-preloader"></div>
            <div class="swiper-slide-title">
                <h3 class="gradient-text">App软件服务</h3>
                <p>专为大谷物联摄像头定制的一款应用程序。无论身在何处，都可以通过手机随时掌握监</p>
              <p>控情况，让您的安全得到更好的保障。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 大数据管理系统 -->
    <div id="bigData" class="container-fuild">
      <div class="row bigData-container">
        <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn">
          <img class="img-responsive" src="@/assets/img/appcontent.png">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6">
          <div class="bigData-title">
            <img src="@/assets/img/logo.png" style="width: 120px;">
          </div>
          <h4 class="bigData-device">大谷云</h4>
          <p>包括Android和iOS版本，它提供了便捷的远程监控和管理功能，让用户可以随时随地通过手机实时查看摄像头的视频画面。</p>
          <div class="erweima-model">
            <div class="erweima-div">
              <p class="model">扫码下载</p>
              <img src="@/assets/img/andriod-ios.png" class="erweima">
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer ></Footer>

  </div>
</template>
<script>
import Swiper from "swiper";
import { WOW } from 'wowjs';
export default {
  name: "HomePage",
  mounted() {
    /* banner-swiper */
    new Swiper(".banner-swiper", {
      loop: true, // 循环模式选项
      effect: 'fade',
      //自动播放
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      // 延迟加载
      lazy: {
        loadPrevNext: true
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true //修改swiper的父元素时，自动初始化swiper
    });
    /* customer-swiper */
    /* wowjs动画 */
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    })
    wow.init();
  }
};
</script>
<style scoped>
/*二维码*/
.erweima{
  width: 100%;
  height: auto;
}
.erweima-model{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#bigData .model{
  font-size: 25px;
}
.erweima-div{
  padding: 20px;
  text-align: center;
  width: 40%;
}
/* 整体盒子 */
#HomePage {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
}
.zoomIn{
  display: flex;
  justify-content: center;
}
/* 轮播图 */
#swiper {
  height: 310px;
}
#swiper .banner-swiper {
  width: 100%;
  height: 100%;
}
#swiper .banner-swiper .swiper-slide img {
  width: 100%;
  height: 100%;
}
#swiper .banner-swiper .swiper-slide{
  position: relative;
}
#swiper .banner-swiper .swiper-slide-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  color: #fff;
  padding:3% 0% 0% 20%;
  background-image: linear-gradient(115deg,#83C0EF 0%,#f5f1e8 30%,#4162FF 100%,#5C62D4 70%);

  line-height: 37px;
}
#swiper .banner-swiper .swiper-slide-title > h1{
  font-size: 50px;
  margin-top: 12%;
}
#swiper .banner-swiper .swiper-slide-title > p{
    font-size: 14px;
    color: black;
  height: 20px;
  margin: auto;

}
/* App产品介绍 */
#bigData {
  padding: 100px 220px;
  transition: all ease 0.6s;
  box-sizing: border-box;
  animation: fadeIn 2s ease-in-out forwards;
}
.row{
  padding: 5% 12%;
  background-image: linear-gradient(115deg,#83C0EF 0%,#f5f1e8 30%);
  border-radius: 40px;
}
#bigData .bigData-title {
  padding-top: 70px;
  padding-bottom: 10px;
}
.gradient-text {
  font-size: 42px;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: black;
  font-weight: bolder;
}
#bigData p {
  font-size: 15px;
  color: #333;
  line-height: 2rem;
}
#bigData .bigData-device {
  font-weight: 800;
  font-size: 40px;
  margin: 50px 0 20px;
  background: linear-gradient(to right, #ff6699, #66ccff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  //color: linear-gradient(115deg,#83C0EF 0%,#f5f1e8 30%);
}




/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  #swiper {
    height: 200px;
  }
  #bigData {
    padding: 30px;
  }
  #bigData .bigData-device {
    font-size: 20px;
    margin: 10px 0 10px;
  }


}

/* 媒体查询（平板） */
@media screen and (min-width: 768px) and (max-width: 996px) {
  #swiper {
    height: 400px;
  }
  #bigData {
    padding: 60px;
  }
  #bigData .bigData-device {
    font-size: 30px;
    margin: 30px 0 15px;
  }
  #contactUs .contactUs-container h1 {
    font-size: 30px;
  }
  #contactUs .contactUs-container h3 {
    font-size: 20px;
  }
  #contactUs .contactUs-container button {
    width: 300px;
    height: 50px;
    margin-top: 30px;
  }
  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 15px;
  }
}
</style>

