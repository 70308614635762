<script>
export default {
  name: 'PrivacyPolicy',
  // 组件的其他选项和逻辑
}
</script>

<template>
  <div id="privacy" data-v-5baf5f4a=""><h1 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong
    data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">大</span></strong><strong
    data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">谷云</span></strong><strong
    data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">隐私政策</span></strong></h1>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">我们的隐私政策已于2023年11月24日更新，并于2023年11月24日生效。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">请花一些时间熟悉我们的隐私政策，如果您有任何问题，请联系我们。</span>
    </p>
    <h3 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">引言</span></strong></h3>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">深圳</span></span><span style="font-family:宋体;font-size:14px;"
                                                                           data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">大谷科技有</span></span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">限</span></span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">公司</span></span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">（下文简称</span>“</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">深圳大谷</span></span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">”或“我们”）非常重视您的隐私。大谷</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""></span><span style="font-family:宋体;font-size:14px;"
                                                                               data-v-5baf5f4a="">（下文简称“大谷”或“大谷云”）是由</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">深圳大谷</span></span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">为您提供智能设备的控制、管理的平台。</span></p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">我们非常重视您的隐私。本隐私政策在制定时充分考虑到您的需求，您全面了解我们的个人信息收集和使用惯例，同时确保您最终能控制提供给我们的个人信息，这一点至关重要。本隐私政策规定我们如何收集、使用、披露、处理和存储您使用我们的</span>大谷云时提供给我们的信息。本隐私政策下“个人信息”指通过信息本身或通过关联其他信息后能够识别特定个人的数据。我们将严格遵守本隐私政策来使用这些信息。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">我们希望为用户带来最好的体验。如果您对本隐私政策中描述的个人信息处理实践有任何疑问，请通过访问</span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;"
      data-v-5baf5f4a="">http://daguiot.com </span><span style="font-family:宋体;font-size:14px;"
                                                              data-v-5baf5f4a="">联系我们，以便我们处理您的特殊需求。我们很高兴收到您的反馈。</span>
    </p>
    <h3 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">一、我们收集哪些信息以及如何使用信息</span></strong>
    </h3><h4 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">（一）您须授权我们收集和使用您个人信息的情形</span></strong></h4>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">收集个人信息的目的在于向您提供产品和</span>/或服务，并且保证我们遵守适用的相关法律、法规及其他规范性文件。您有权自行选择是否提供该信息，但多数情况下，如果您不提供，我们可能无法向您提供相应的服务，也无法回应您遇到的问题。这些功能包括：</span>
    </p><h5 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">（</span>1） 智能设备连接</span></strong>
    </h5>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">为了给您提供</span>大谷云的服务，使您可以安全地连接、管理智能设备，我们可能会收集您的Wi-Fi信息、IP地址、位置信息、登录帐号信息、手机相关信息，智能设备相关信息，以及大谷云帐号和智能设备的关联信息。这些信息将用于为您提供智能设备快连、连接、发现附近设备和设备管理的功能。上述各项信息，详细列举如下：</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">登录帐号信息：</span>大谷云帐号、昵称、头像信息。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">手机相关信息：硬件设备标识</span>(imsi、imei、meid、设备硬件序列号、SIM 卡标识、OAID、Mac地址、Android ID)、手机型号、系统版本信息、系统语言、手机所设置的国家或地区、应用商店的版本号、手机屏幕尺寸及分辨率、CPU及显示设备相关信息。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">连接智能设备过程中收集的信息：基于您需要链接的智能设备的类型，我们可能会收集如下信息：</span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">通过</span>Wi-Fi连接的智能设备：Wi-Fi信息（SSID,BSSID, Wi-Fi的Mac地址、Wi-Fi密码）、设备Mac地址、设备ID。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">通过蓝牙建立本地连接后，通过</span>Wi-Fi连接的智能设备：Wi-Fi信息（SSID,BSSID, Wi-Fi的Mac地址、Wi-Fi密码）、设备Mac地址、设备蓝牙Mac地址。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">通过蓝牙连接的智能设备：设备蓝牙</span>Mac地址、设备ID。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">通过</span>Zig-bee方式连接的智能设备：设备Mac地址、设备ID。</span>
    </p><h5 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">（</span>2） 智能设备的家庭管理：</span></strong>
    </h5>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">我们可能收集您提供的智能设备的房间设置信息，用于构建智能设备的家庭管理，这将使您享受到更便捷的智能设备操作体验（例如当有多款灯的时候，可以快速定位到管理的是卧室的灯，而非客厅的灯）。</span>
    </p><h5 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">（</span>3） 设备共享</span></strong>
    </h5>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">我们支持您将智能设备通过</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">深圳大谷</span></span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">帐号和微信，分享他人一起使用。经分享后，被分享者同样可以对智能设备进行控制。为了享受此功能，我们可能收集您的帐号</span>ID、您提供的分享帐号ID、被分享的设备信息（设备ID、设备名称、设备验证key、设备的分享状态），用于为您提供将设备分享给其他</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">深圳大谷</span></span><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">帐号的用户共同控制、使用的功能，以及用于设备列表页展示分享状态。</span></p><h5
      style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">（</span>4） 应用和智能硬件升级：</span></strong>
    </h5>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">为了使您持续享受最新的</span>大谷云服务，我们可能会使用您大谷云应用的版本信息、手机型号用于为您提供大谷云应用的升级服务。同时，我们可能收集您已连接的智能设备列表和版本号信息，用于为您提供智能设备的升级功能，以确保您可以使用最新版本的服务（包括固件版本）。</span>
    </p><h5 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">（</span>5）推荐智能产品：</span></strong>
    </h5>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">为了使您更好的享受智能设备带来的便利生活，我们可能会使用您已绑定的智能设备信息，为您推荐能与您已绑定设备进行智能联动的其他智能设备。（例如：当您已绑定台灯时，我们会为您推荐能与台灯智能联动的网关和传感器等设备）。</span>
    </p><h5 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">（</span>6） 智能场景（设备智能联动）：</span></strong>
    </h5>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">我们支持您通过设定一定规则，从而实现特定条件下智能设备间的联动功能。为了享受此功能，我们可能收集您的位置信息、您对智能场景的规则设置、您设置的作息时间（如果您是</span>MIUI系统用户，我们会在您授权后为您同步系统作息时间设置），以及您指定的设备状态，以实现依照您指定的执行命令来执行设备的功能。（例如当传感器察觉到有人经过的时候，灯亮起）。请您放心，该功能需要您主动开启并设定规则。</span>
    </p><h5 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">（</span>7）提供内容支持（例如文章、有声内容播放）：</span></strong>
    </h5>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">为了帮助您更好的使用智能设备，我们会为您提供相应的精选文章，向您介绍与智能设备相关的内容。阅读这些内容时，我们不会收集您的任何信息。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">如果您在</span>大谷云上连接了支持内容播放的智能设备，您可以在内容支持页面上选择和控制此智能设备播放的音乐或内容。我们可能会收集您账号下绑定的智能设备信息，以为您提供相应的智能设备控制功能。</span>
    </p><h5 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">（8）</span><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">提供</span></strong><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">云存储订购服务</span></strong><strong
      data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">：</span></strong></h5>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">实现下单交易功能，您需要提供您的订单信息、收货人姓名、收货地址、收货人手机号码，我们将使用这类信息来确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务，我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。若您不提供这类信息，您可能无法正常进行商品或服务的购买，但不影响您正常使用其他功能。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">支付功能由第三方支付平台（微信、支付宝）向您提供服务。当您使用第三方支付平台支付时，我们需要将您的订单号、交易金额、支付方式及其他反洗钱法律要求的必要信息与第三方支付平台共享以实现支付功能</span>
    </p><h5 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">（9）</span><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">消息推送服务：</span></strong></h5>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">您在使用消息推送技术服务时，我们需要使用您的</span>Android ID、IMEI、OAID及IP地址以进行链路调节，监听系统自启动的广播，使用极光SDK监听App的卸载和安装，相互促活被关闭的SDK推送进程，保障您可以及时接收到我们向您推送的消息。如您希望关闭此功能，可以通过设置--是否允许关联启动发送通知消息来关闭本功能，关闭本功能并不影响您正常使用本应用。</span>
    </p><h4 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">（二）您可选择是否授权我们收集和使用您的个人信息的情形</span></strong></h4>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">为使您更好的享受</span>大谷云为您提供的智能设备的控制、管理等，我们的以下附加功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以使用大谷云的基础服务，但您可能无法使用这些可以为您所带来便利的附加功能。这些附加功能包括：</span>
    </p><h5 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">（</span>1）人脸识别管理</span></strong>
    </h5>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">如果您通过</span>大谷云平台连接了支持人脸识别功能的特定产品，您可以开启大谷云支持的人脸识别功能以获得更丰富的智能设备体验。您可以录入需要识别的人脸素材，当我们识别到被标注的人脸时，我们会在智能设备中为您标记和推送消息。您可以在“我的-设置-人脸识别管理”中控制产品的人脸识别功能开关。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">如果您对我们识别的结果有疑问，您可以和我们反馈以帮助我们优化算法和模型。在此情况下，我们可能会收集您主动反馈的问题视频。请您放心，您反馈的视频仅会用于优化算法和模型，不会用于其他用途。</span>
    </p><h5 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">（</span>2） 用户反馈：</span></strong>
    </h5>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">我们可能收集您发给我们的反馈的问题、反馈日志，以及您填写的电话号码或邮箱，这些信息将用于让我们更好的了解您遇到的问题，以及联系您。</span>
    </p><h4 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">（三）</span>大谷云权限调用说明</span></strong>
    </h4>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">此外，在您使用</span>大谷云时可能会触发以下设备权限。为使您更好地管理您的信息，我们会在产品使用过程权限申请时同步告知您该权限的使用目的，以及如您不开启该权限可能对您使用服务的影响，请您仔细查阅。您在使用特定功能前，可选择是否授权开通以下权限。同时，您可以随时通过设备权限设置页面自己更改授权情况。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">以下为相关功能及调用权限的细节，为您列举供参考：</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">1.读写外部存储空间（Android版本）：用于在扫一扫、用户反馈等功能中读写设备上的照片及文件。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">2.位置权限（Android、iOS版本）：用于扫描附近WLAN列表、搜索附近蓝牙设备以及智能场景中自动化地理围栏等功能；当用户绑定了不支持</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">GPS</span><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">的设备时，如智能手表，用于提供步频计算、运动准确距离的记录、画出用户的运动轨迹等功能。</span></p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">3.获取手机账户列表（MIUI系统上）：用于获取手机的账户列表以便快捷登录。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">4.相机权限（Android、iOS版本）：用于扫描二维码以进行设备安装、账号登录等功能。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">5.麦克风权限（Android、iOS版本）：当用户在大谷云中绑定了摄像机、可视门铃等设备时，用于视频对讲、语音指令的拾音等功能。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">6.读取电话权限（Android）：用于在客户端本地生成设备id。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">7.开启WLAN权限（Android、iOS版本）：用于快连设备时，连接设备发出的WIFI，以进行设备通信。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">8.开启蓝牙权限（Android、iOS版本）：用于在快连设备时，连接设备发出的蓝牙信号，以进行设备通信；对于通过蓝牙连接的智能设备，也需要蓝牙权限用于通信。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">9.悬浮窗权限（Android版本）：当用户在大谷云中绑定了摄像机，用于在悬浮窗中展示摄像机实时画面。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">10.</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">消息推送权限（</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">A</span><span style="font-family:宋体;font-size:14px;"
                                                                                data-v-5baf5f4a="">ndroid版本）：当发生事件告警时，通过消息渠道推送消息至用户手机</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">.</span></p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">11、软件安装列表（Android、iOS版本）：用于关联启动的推送服务进行消息的推送和接收。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">12、修改语言配置（Android、iOS版本）：用于改变App中的语言显示（如从简体中文修改为English）。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">13、访问网络状态（Android、iOS版本）：通过对网络质量的判定优化视频通讯效率。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">14、访问WiFi状态（Android、iOS版本）：通过对网络质量的判定优化视频通讯效率。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">15、改变WiFi状态（Android、iOS版本）：当设备连接的网络发送变化时，需要使用该权限完成WiFi网络的更改和配置。</span>
    </p><h4 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">（四）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的同意：</span></strong></h4>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">1.&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">与国家安全、国防安全有关的；</span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">2.&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">与公共安全、公共卫生、重大公共利益有关的；</span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">3.&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">与犯罪侦查、起诉、审判和判决执行等有关的；</span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">4.&nbsp;</span><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">5.&nbsp;</span><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">所收集的个人信息是个人信息主体自行向社会公众公开的；</span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">6.&nbsp;</span><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">7.&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">根据您的要求签订合同所必需的；</span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">8.&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">用于维护所提供的产品与</span>/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">9.&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">为合法的新闻报道所必需的；</span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">10.&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span>
    </p><h4 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">（五）我们从第三方获得您个人信息的情形</span></strong>
    </h4>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">在一些法律允许的情况下，我们可能从第三方处获得您的个人信息。例如，在智能场景（设备智能联动）功能中，在您授权的前提下，我们可能从智能设备处，获得智能设备的设备状态，以此来判断是否触发智能场景（例如，当您设定传感器感应到有人经过时，灯亮起）。您可以在</span>“智能”页面下，添加或删除设定的智能联动规则，也可以在智能设备的页面中管理是否开启或删除智能联动。</span>
    </p><h4 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">（六）非个人信息</span></strong></h4>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">我们还可能收集其他无法识别到特定个人的信息（即不属于个人信息的信息），例如您使用特定服务时产生的统计类数据，如用户的操作行为（包括点击、页面跳转、浏览时间）。收集此类信息的目的在于改善我们向您提供的服务。所收集信息的类别和数量取决于您如何使用我们产品和</span>/或服务。 我们会将此类信息汇总，用于帮助我们向客户提供更有用的信息，了解客户对我们网站、产品和服务中的哪些部分最感兴趣。就本隐私政策而言，汇总数据被视为非个人信息。如果我们将非个人信息与个人信息结合使用，则在结合使用期间，此类信息将被视为个人信息。</span>
    </p>
    <h3 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">二、我们如何使用</span> Cookie 和同类技术</span></strong>
    </h3>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">大谷云和第三方服务供应商使用 Cookie、标签和脚本等技术，这些技术用于分析趋势、管理网站、追踪用户的网站活动并收集关于整个用户群体的统计信息。我们会收到通过以上技术收集的个体或汇总的分析报告。此等技术帮助我们更好地了解您的行为，使我们了解用户浏览我们网站的哪些部分，衡量广告和网络搜索的效果并加以改善。我们将通过Cookie 和其他技术收集的信息视为非个人信息。但是，如果当地法律将 Internet 协议 (IP) 地址或类似识别标记视为个人信息，则我们亦将此等识别标记视为个人信息。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">日志文件：和大部分网站一样，我们收集特定信息并保存在日志文件中。此类信息可能包括互联网协议</span> (IP) 地址、浏览器类型、互联网服务供应商(ISP)、引用/退出页面、操作系统、日期/时间戳和/或点击流数据。我们不会把自动收集的日志数据与我们收集的其他信息进行关联。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">广告：我们与第三方服务供应商合作，在我们的网站上展示广告或管理我们在其他网站上的广告。我们的第三方服务供应商可能会使用诸如</span> Cookie等技术来收集您在其网站或其他网站上的活动信息，基于您的浏览活动和兴趣为您提供广告。我们会在向您提供广告服务之前，会获取您事前的明确同意。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">移动分析：在某些移动应用中，我们使用移动分析软件，以更好地了解我们的移动软件在您的手机中的功能。此款软件可能记录以下信息，如您使用该应用程序的频率、该应用程序内发生的事件、累计使用、性能数据及应用程序崩溃发生的位置。我们不会将存储于分析软件内的信息关联到您在移动应用程序中提交的任何个人信息。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">本地存储</span> - HTML5/Flash：我们使用本地存储对象 (LSO)，例如 HTML5 或 Flash 存储内容和偏好。根据您的网页浏览行为，与我们合作在网站上提供某些功能或投放广告的第三方也会通过 HTML5 或 Flash Cookie 来收集和存储相关信息。各种浏览器提供了各自的管理工具来删除 HTML5 本地存储对象。</span>
    </p>
    <h3 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                             data-v-5baf5f4a="">三、</span><strong
      data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                               data-v-5baf5f4a="">我们如何与第三方合作伙伴共享信息和使用方式</span></strong></h3>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">消息推送功能：如您使用小米手机时，小米推送</span><span
      style="font-family:Calibri;font-size:14px;" data-v-5baf5f4a="">SDK</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">需要获取您设备的存储权限并收集唯一设备识别码（</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">Android ID</span><span style="font-family:宋体;"
                                                                             data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">IMEI</span><span style="font-family:宋体;"
                                                                       data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">IMSI</span><span style="font-family:宋体;" data-v-5baf5f4a="">）用于推送</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">/</span><span style="font-family:宋体;" data-v-5baf5f4a="">下发通知栏消息；如您使用</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">OPPO</span><span style="font-family:宋体;"
                                                                       data-v-5baf5f4a="">手机时，</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">OPPO</span><span style="font-family:宋体;"
                                                                       data-v-5baf5f4a="">推送</span></span><span
      style="font-family:Calibri;font-size:14px;" data-v-5baf5f4a="">SDK</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">需要获取您设备的存储权限并收集唯一设备识别码（</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">Android ID</span><span style="font-family:宋体;"
                                                                             data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">IMEI</span><span style="font-family:宋体;"
                                                                       data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">IMSI</span><span style="font-family:宋体;" data-v-5baf5f4a="">）用于推送</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">/</span><span style="font-family:宋体;" data-v-5baf5f4a="">下发通知栏消息；如您使用华为手机时，华为推送</span></span><span
      style="font-family:Calibri;font-size:14px;" data-v-5baf5f4a="">SDK</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">需要获取您设备的存储权限并收集唯一设备识别码（</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">Android ID</span><span style="font-family:宋体;"
                                                                             data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">IMEI</span><span style="font-family:宋体;"
                                                                       data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">IMSI</span><span style="font-family:宋体;" data-v-5baf5f4a="">）用于推送</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">/</span><span style="font-family:宋体;" data-v-5baf5f4a="">下发通知栏消息；如您使用其他型号手机时，极光</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">SDK</span><span style="font-family:宋体;" data-v-5baf5f4a="">将会收集您的设备标识符（</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">IMEI</span><span style="font-family:宋体;"
                                                                       data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">IDFA</span><span style="font-family:宋体;"
                                                                       data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">Android ID</span><span style="font-family:宋体;"
                                                                             data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">IMSI</span><span style="font-family:宋体;"
                                                                       data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">MAC</span><span style="font-family:宋体;"
                                                                      data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">OAID</span><span style="font-family:宋体;" data-v-5baf5f4a="">相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">IP</span><span style="font-family:宋体;"
                                                                     data-v-5baf5f4a="">地址，</span></span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">WiFi</span><span style="font-family:宋体;" data-v-5baf5f4a="">信息，基站信息用于推送</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">/</span><span style="font-family:宋体;" data-v-5baf5f4a="">下发通知栏消息。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">关联启动功能：当您启动</span><span
      style="font-family:Calibri;font-size:14px;" data-v-5baf5f4a="">大谷云 </span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:Calibri;" data-v-5baf5f4a="">APP</span><span
      style="font-family:宋体;"
      data-v-5baf5f4a="">程序时，会关联启动华为推送服务和极光推送服务；当华为推送服务和极光推送服务启动时，会关联启动</span></span><span
      style="font-family:Calibri;font-size:14px;" data-v-5baf5f4a="">大谷云 </span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:Calibri;" data-v-5baf5f4a="">APP</span><span
      style="font-family:宋体;" data-v-5baf5f4a="">推送进程。关联启动的推送服务用于消息的推送和接收。</span></span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">支付功能：如您通过第三方支付渠道购买增值服务，第三方支付服务提供商（支付宝、微信支付）会获取您设备的存储权限并收集您的唯一设备识别码（</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">Android ID</span><span style="font-family:宋体;"
                                                                             data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">IMEI</span><span style="font-family:宋体;"
                                                                       data-v-5baf5f4a="">、</span><span
      style="font-family:Calibri;" data-v-5baf5f4a="">IMSI</span><span style="font-family:宋体;"
                                                                       data-v-5baf5f4a="">）。</span></span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">诊断功能：<span
      style="font-family:Calibri;" data-v-5baf5f4a="">com.qiniuSDK</span>需要诊断相关服务器的地址能否正常访问，便于定位问题。</span></span>
    </p>
    <h3 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">四、</span></strong><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">保留政策</span></strong></h3>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">我们基于本隐私政策中所述的信息收集的目的所必需的期间，或者遵守适用的相关法律要求保留个人信息。个人信息在完成收集目的，或在我们确认您的删除或注销申请后，或我们终止运营相应产品或服务后，我们将停止保留，并做删除或匿名化处理。如果是出于公众利益、科学、历史研究或统计的目的，我们将基于适用的法律继续保留相关数据，即使进一步的数据处理与原有的收集目的无关。</span>
    </p>
    <h3 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">五、</span></strong><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">您的权利</span></strong></h3><h4
      style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">（一）控制设置</span></strong></h4>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;"
      data-v-5baf5f4a="">我们承认每个人对隐私权的关注各不相同。因此，我们提供了一些示例，说明</span>大谷云提供的各种方式，供您选择，以限制收集、使用、披露或处理您的个人信息，并控制您的隐私权设置： 打开或者关闭位置访问、相机、录音和读写手机存储权限；</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">开启或关闭设备智能联动；</span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                        data-v-5baf5f4a="">在</span>大谷云“我的-设置-人脸识别管理”中控制产品的人脸识别功能开关</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">登入或登出</span>大谷云账户。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;"
      data-v-5baf5f4a="">如果您之前因为上述目的同意我们使用您的个人信息，您可以随时通过书面或者通过访问</span>&nbsp;</span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;"
      data-v-5baf5f4a="">http://daguiot.com </span><span style="font-family:宋体;font-size:14px;"
                                                              data-v-5baf5f4a="">&nbsp;联系我们来改变您的决定。</span>
    </p><h4 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">（二）您对您的个人信息享有的权利</span></strong></h4>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">根据您所适用的国家或地区法律法规，您有权要求访问、更正、删除我们持有的与您相关的任何个人信息（以下简称请求）。</span>
    </p>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a=""><span
      style="font-family:宋体;" data-v-5baf5f4a="">与您</span>大谷云帐号中的个人信息相关的更多详细信息，您可以通过您的设备登入帐号来访问和更改。其他信息，请致信或者通过访问</span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;"
      data-v-5baf5f4a="">http://daguiot.com </span><span style="font-family:宋体;font-size:14px;"
                                                              data-v-5baf5f4a="">&nbsp;联系我们。 大多数法律要求个人信息主体提出的请求应遵循特定要求，本隐私政策要求您的请求应当符合以下情形：</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">通过我们专门的请求渠道，并且出于保护您的信息安全的考虑，您的请求应当是书面的（除非当地法律明确承认口头申请）；</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">提供足够的信息使我们可以验证您的身份，确保请求人是所请求信息主体本人或合法授权人；</span></p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">一旦我们获得充分信息确认可处理您的请求时，我们将在适用数据保护法律规定的时间内对您的请求做出回应。具体而言：</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">基于您的要求及适用法律规定，我们可免费提供一份我们已收集并处理的关于您的个人信息记录，</span> 如您提出对于相关信息的其他请求，我们可能会基于相关适用法律，并结合实际的管理成本向您收取一笔合理的费用。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">如果您认为我们持有的关于您的任何信息是不正确或不完整的，可要求基于使用目的更正或完善个人信息。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">根据您适用的法律法规，您可能有权要求我们删除您的个人数据。我们将会根据您的删除请求进行评估，若满足相应规定，我们将会采取包括技术手段在内的相应步骤进行处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。例如，根据《中华人民共和国电子商务法》，您的商品和服务信息、交易信息保存时间自交易完成之日起不得少于三年。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">我们有权拒绝处理无实质意义</span>/纠缠式重复的请求、损害他人隐私权的请求、极端不现实的请求，要求不相称的技术工作，以及根据当地法律无需给予的请求，已经公之于众的信息，保密条件下给出的信息。如果我们认为删除数据或访问数据的请求的某些方面可能会导致我们无法出于前述反欺诈和安全目的合法使用数据，可能也会予以拒绝。</span>
    </p><h4 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">（三）撤销同意</span></strong></h4>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">您可以通过提交请求撤销同意，包括收集、使用和</span>/或披露我们掌握或控制的您的个人信息。根据您所使用的具体服务，您可以在应用内通过“我的”-“用户协议和隐私政策”来实现，或者可以通过访问</span><span
      style="font-family:宋体;color:rgb(65,131,196);" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                               data-v-5baf5f4a="">http://</span></span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                                              data-v-5baf5f4a="">www</span></span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a="">.</span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a="">daguiot</span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a="">.com</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">联系我们进行相关操作。我们将会在您做出请求后的十五天内处理您的请求，并且会根据您的请求，在此后不再收集、使用和</span>/或披露您的个人信息。</span>
    </p>
    <p style="margin-left:28px;" data-v-5baf5f4a=""><span style="font-family:Wingdings;font-size:14px;"
                                                          data-v-5baf5f4a="">l&nbsp;</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">请注意，您撤销同意会导致某些法律后果。根据您授权我们处理信息的范围，这可能导致您不能享受深圳大谷的产品或服务。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</span>
    </p><h4 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">（四）注销服务或账号</span></strong></h4>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">如您希望注销具体产品或服务，您可以通过访问</span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                                              data-v-5baf5f4a="">http://</span></span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                                              data-v-5baf5f4a="">www</span></span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a="">.</span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a="">daguiot</span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a="">.com</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">联系我们进行服务注销。如您希望注销</span>大谷云帐号，由于注销大谷云帐号的操作将使您无法使用产品和服务，请您谨慎操作。我们为了保护您或他人的合法权益会结合您对</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">深圳大谷</span></span><span
      style="font-family:宋体;font-size:14px;"
      data-v-5baf5f4a="">各产品和服务的使用情况判断是否支持您的注销请求。例如存在尚未偿还的分期，则我们不能立即支持您的请求。</span>
    </p>
    <h3 style="margin-top:27px;margin-bottom:13px;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">六</span></strong><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">、联系我们</span></strong></h3>
    <p style="margin-top:20px;margin-bottom:20px;" data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                                                                            data-v-5baf5f4a="">如果您对本隐私政策有任何意见或问题，或者您对我们收集、使用或披露您的个人信息有任何问题，请通过下方地址或通过访问</span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                                              data-v-5baf5f4a="">http://</span></span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;"
                                                                                              data-v-5baf5f4a="">www</span></span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a="">.</span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a="">daguiot</span><span
      style="font-family:宋体;color:rgb(65,131,196);font-size:14px;" data-v-5baf5f4a="">.com</span><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a=""><span style="font-family:宋体;" data-v-5baf5f4a="">联系我们，并提及</span>“隐私政策”。针对您关于个人信息相关的权利请求、问题咨询等时，我们有专业的团队解决你的问题。如果你的问题本身涉及比较重大的事项，我们可能会要求你提供更多信息。如果您对收到的答复不满意，您可以将投诉移交给所在司法辖区的相关监管机构。如果您咨询我们，我们会根据您的实际情况，提供可能适用的相关投诉途径的信息。</span>
    </p>

    <p data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">&nbsp;</span></strong>
    </p>
    <p data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">&nbsp;</span></strong>
    </p>
    <p data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">&nbsp;</span></strong>
    </p>
    <p data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">&nbsp;</span></strong>
    </p>
    <p data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">&nbsp;</span></strong>
    </p>
    <p data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">&nbsp;</span></strong>
    </p>
    <p data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">&nbsp;</span></strong>
    </p>
    <p data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">&nbsp;</span></strong>
    </p>
    <p data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">&nbsp;</span></p>
    <p data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">&nbsp;</span></strong>
    </p>
    <p style="text-align:right;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">本版发布</span></strong><strong
      data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">/</span></strong><strong
      data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;"
                               data-v-5baf5f4a="">更新日期：</span></strong><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">2023</span></strong><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">年</span></strong><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">11</span></strong><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">月</span></strong><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">24</span></strong><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">号</span></strong></p>
    <p style="text-align:right;" data-v-5baf5f4a=""><strong data-v-5baf5f4a=""><span
      style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">生效日期：</span></strong><strong
      data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">2023</span></strong><strong
      data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">年</span></strong><strong
      data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">11</span></strong><strong
      data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">月</span></strong><strong
      data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">24</span></strong><strong
      data-v-5baf5f4a=""><span style="font-family:宋体;font-size:14px;" data-v-5baf5f4a="">号</span></strong></p>
    <p data-v-5baf5f4a=""><br data-v-5baf5f4a=""></p></div>
</template>

<style scoped>
.el-dropdown-menu__item {
  padding: 15px 15px!important
}

.content_ .el-dropdown__popper {
  position: absolute!important;
  left: 5px!important;
  top: 60px!important
}

header {
  float: left;
  width: 100%;
  height: 60px;
  background: #fff;
  opacity: .75;
  box-shadow: 0 5px 8px 0 rgba(0,0,0,.05);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9
}

header .content_ {
  margin: 0 auto;
  width: 1280px;
  height: 60px;
  display: flex
}

header .content_ div {
  width: 100px;
  text-align: center;
  line-height: 60px;
  font-size: 14px
}

header .content_ div .el-dropdown-link {
  position: relative;
  color: #000;
  height: 40px
}

header .content_ div .el-dropdown-link:hover {
  cursor: pointer;
  color: #2facfe
}

header .content_ img {
  margin-top: 10px;
  width: 113px;
  height: 38px
}

header .content_ div:hover,header .content_ img:hover {
  cursor: pointer
}

.register_button {
  padding-top: 15px
}

.content_ .register div {
  width: 72px;
  height: 34px;
  background: #2facfe;
  font-size: 14px;
  font-family: PingFang SC-Regular,PingFang SC;
  font-weight: 400;
  color: #fafcfe;
  line-height: 34px;
  text-align: center
}

.content_ .register div:hover {
  cursor: pointer;
  background-color: #164ee7
}

.el-dropdown-menu {
  padding: 0
}

.el-dropdown-menu .disover {
  display: none
}

.el-dropdown-menu .solution {
  width: calc(100vw - 30px);
  min-width: 1260px;
  height: 300px
}

.el-dropdown-menu .solution .solution_left {
  width: 30%;
  height: 100%;
  background: rgba(78,135,244,.05);
  float: left;
  text-align: right
}

.el-dropdown-menu .solution .solution_left .title {
  margin-right: 120px;
  font-size: 14px;
  font-family: MiSans-Medium,MiSans;
  font-weight: 550;
  color: #333;
  line-height: 24px;
  margin-top: 41px
}

.el-dropdown-menu .solution .solution_left .text {
  float: right;
  text-align: left;
  width: 159px;
  height: 48px;
  font-size: 12px;
  font-family: MiSans-Normal,MiSans;
  font-weight: 400;
  color: #333;
  line-height: 24px;
  margin-right: 17px;
  margin-top: 5px
}

.el-dropdown-menu .solution .solution_right {
  width: 68%;
  float: left
}

.el-dropdown-menu .solution .solution_right .solution_content {
  width: 280px;
  float: left
}

.el-dropdown-menu .solution .solution_right .solution_content .title {
  height: 24px;
  font-size: 14px;
  font-family: MiSans-Medium,MiSans;
  font-weight: 500;
  color: #2facfe;
  line-height: 24px;
  margin-top: 41px;
  margin-left: 58px
}

.el-dropdown-menu .solution .solution_right .solution_content .text {
  margin-top: 5px;
  margin-left: 43px
}

.el-dropdown-menu .support {
  width: calc(100vw - 30px);
  min-width: 1260px;
  height: 190px
}

.el-dropdown-menu .support .support_left {
  width: 30%;
  height: 100%;
  background: rgba(78,135,244,.05);
  float: left
}

.el-dropdown-menu .support .support_left .support_content {
  float: right;
  width: 185px
}

.el-dropdown-menu .support .support_left .support_content .support_title {
  font-size: 14px;
  font-family: MiSans-Medium,MiSans;
  font-weight: 550;
  color: #333;
  padding-top: 41px
}

.el-dropdown-menu .support .support_left .support_content .support_text {
  width: 159px;
  font-size: 12px;
  font-family: MiSans-Normal,MiSans;
  font-weight: 400;
  color: #333;
  line-height: 24px;
  margin-top: 10px
}

.el-dropdown-menu .support .support_right {
  width: 68%;
  float: left
}

.el-dropdown-menu .support .support_right .support_content {
  float: left;
  margin-left: 58px;
  margin-right: 20px;
  width: 215px;
  margin-top: 26px
}

.el-dropdown-menu .support .support_right .support_content .support_text {
  margin-left: 15px;
  font-size: 14px;
  font-family: MiSans-Normal,MiSans;
  font-weight: 400;
  color: #333;
  line-height: 24px;
  margin-top: 10px
}

.el-dropdown-menu .product {
  width: calc(100vw - 30px);
  min-width: 1260px;
  height: 300px
}

.el-dropdown-menu .product .product_left {
  width: 30%;
  background: rgba(78,135,244,.05);
  height: 300px;
  float: left
}

.el-dropdown-menu .product .product_left .items {
  float: right;
  width: 171px;
  text-align: left
}

.el-dropdown-menu .product .product_left .items .item {
  font-size: 12px;
  font-family: MiSans-Normal,MiSans;
  font-weight: 400;
  color: #333
}

.el-dropdown-menu .product .product_left .items .item .title {
  float: left;
  height: 65px;
  line-height: 95px
}

.el-dropdown-menu .product .product_left .items .item .img1 {
  float: left;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-top: 31px
}

.el-dropdown-menu .product .product_left .items .item .img2 {
  float: left;
  margin-top: 35px;
  margin-right: 16px;
  width: 20px;
  height: 20px
}

.el-dropdown-menu .product .product_left .items .item:hover {
  cursor: pointer;
  color: #2facfe
}

.el-dropdown-menu .product .product_right {
  width: 69%;
  height: 300px;
  overflow-x: auto;
  float: left
}

.el-dropdown-menu .product .product_right .windows {
  margin-right: 310px;
  margin-left: 0
}

.el-dropdown-menu .product .product_right .windows .windows_title1 {
  margin-top: 37px;
  width: 40%;
  float: left;
  margin-left: 41px
}

.el-dropdown-menu .product .product_right .windows .windows_title1 .windows_text {
  font-size: 14px;
  color: #2facfe;
  margin-left: 15px
}

.el-dropdown-menu .product .product_right .windows .windows_title1 div {
  font-size: 12px;
  font-family: MiSans-Normal,MiSans;
  font-weight: 400;
  color: #333;
  margin-bottom: 10px
}

.el-dropdown-menu .product .product_right .windows .windows_title2 {
  margin-top: 37px;
  width: 40%;
  float: left
}

.el-dropdown-menu .product .product_right .windows .windows_title2 .windows_text {
  font-size: 14px;
  color: #2facfe;
  margin-left: 15px
}

.el-dropdown-menu .product .product_right .windows .windows_title2 div {
  font-size: 12px;
  font-family: MiSans-Normal,MiSans;
  font-weight: 400;
  color: #333;
  margin-bottom: 10px
}

#privacy {
  width: 60%;
  margin: 0 auto;
  margin-top: 64px
}
@media screen and (max-width: 997px) {
  #privacy{
    width: 100%;
    margin-top: 0px;
  }
}
</style>
